<template>
  <master-layout
    :showContentHeader="false"
    :showHeader="false"
    :noPadding="true"
  >
    <div class="page">
      <div @click="closeModal" class="ion-padding text-18">
        <ion-icon :icon="arrowBackOutline"></ion-icon>
      </div>

      <div v-for="product in item.items" :key="product.name">
        <div class="item" v-if="product.visibleApp">
          <span
            v-if="products.filter((x) => x.id == product.idProdotto)[0]"
            class="font-bold text-14"
            >{{ products.filter((x) => x.id == product.idProdotto)[0].name }}
          </span>

          <span
            v-if="products.filter((x) => x.id == product.idProdotto)[0]"
            class="text-14"
            >{{
              products
                .filter((x) => x.id == product.idProdotto)[0]
                .price.toLocaleString("it", {
                  style: "currency",
                  currency: "EUR",
                })
            }}
          </span>
          <span class="actual text-14"
            >{{ product.quantita }}
            {{ product.quantita == 1 ? "pezzo" : "pezzi" }}</span
          >
        </div>
      </div>
      <div class="last-button flex justify-content-center">
        <button class="p-d button-primary" @click="addOne">
          Aggiungi al carrello
        </button>
      </div>
    </div>
  </master-layout>

  <!-- <ion-button expand="block" @click="closeModal"> Close</ion-button> -->
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelect,
  IonSelectOption,
  modalController,
  IonIcon,
} from "@ionic/vue"
import { arrowBackOutline } from "ionicons/icons"
import DettaglioOrdine from "./DettaglioOrdine.vue"
import ShoppingCartService from "../../common/mixins/ShoppingCart.vue"
import ApiService from "../../common/service.api"

export default {
  name: "singleItem",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonSelect,
    IonSelectOption,
    modalController,
    IonIcon,
  },
  props: {
    item: Object,
  },
  mixins: [ShoppingCartService],
  created() {
    this.getProducts()
  },
  data() {
    return {
      filter: "",

      arrowBackOutline,
      counter: 0,
      loadingItems: false,

      products: [],
    }
  },
  methods: {
    getProducts() {
      this.loadingItems = true
      ApiService.get("Ecommerce/GetProducts")
        .then((res) => {
          res.data.forEach((prod) => {
            this.products.push({
              id: prod.id,
              code: prod.codiceEan,
              name: prod.nomeProdotto,
              pic: prod.immagineProdotto,
              description: prod.descrizione,
              format: prod.quantitaConfezione,
              price: prod.prezzoPubblico,
              number: 0,
              is_in_bundle: false,
            })
          })
        })
        .finally(() => {
          this.loadingItems = false
        })
    },
    addOne() {
      let currentItem = { ...this.item }
      currentItem.number = 1
      this.addItem(currentItem)
      this.counter++
      this.$router.push("/shopping-cart")
      this.closeModal()
    },
    closeModal() {
      modalController.dismiss()
    },
    dettaglioOrdine() {
      // modalController.dismiss();
      this.openModal(this.item, this.counter)
    },
    async openModal(item, counter) {
      let cliente = {}
      this.aziende.forEach((element) => {
        if (this.clienteSelezionato.includes(element.name)) {
          cliente = JSON.parse(JSON.stringify(element))
        }
      })

      const modal = await modalController.create({
        component: DettaglioOrdine,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
          counter: counter,
          cliente: cliente,
        },
      })
      return modal.present()
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  background: var(--client-ground2-color) !important;
  min-height: 100vh;

  .item {
    width: 90%;
    margin: auto;
    font-size: 14px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 4fr 3fr 3fr 1fr;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}

ion-icon {
  padding-top: 5px;
  font-size: 20px;
}

.numero {
  min-width: 25px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0.7rem;
  background: var(--ion-color-secondary);
  border-radius: var(--ion-border-radius);
}

.counter {
  background: var(--ion-color-secondary);
  border-radius: var(--ion-border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 110px;
  padding: 0.5rem 1rem;
}

.last-button {
  width: 90%;
  margin: auto;
}
</style>
